import { useTranslation } from "react-i18next";

import { ParticipantColor } from "./ParticipantColor"; 
import RichardImg from "../../images/RichardImg.png";
import DinyImg from "../../images/DinyImg.png";
import HeiniImg from "../../images/HeiniImg.png";
import RitaImg from "../../images/RItaImg.png";
import LiesbethImg from "../../images/LiesbethImg.png";
import HenriImg from "../../images/HenriImg.png";


export const SupportedBy = () => { 

  const team = [ 
    {
      name: "Rita Kromhof",
      position: "Commissie Kind&Gezin",
      img: RitaImg
    },
    {
      name: "Heini Veneberg",
      position: "Commissie PR/financiën",
      img: HeiniImg
    },
    {
      name: "Diny Berkhoff",
      position: "Commissie Programma",
      img: DinyImg
    },
    {
      name: "Liesbeth Roks",
      position: "Commissie Programma",
      img: LiesbethImg
    },
    {
      name: "Richard Spikker",
      position: "Commissie Humanitair",
      img: RichardImg
    },
    {
      name: "Henri Kromhof",
      position: "Commissie reizen",
      img: HenriImg
    }
  ]
 

  const { t } = useTranslation();
  return(
    <div className="content-block bg-white">
      <div className="max-w-6xl px-4 m-auto w-full text-center lg:text-left">
        <div className="header-text">
          {t("whoIsSupported")}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          {team.map((participant, index) => (
            <ParticipantColor data={participant} key={index} isNew={false} />
          ))} 
        </div>
      </div>
      <div className="text-center text-2xl mt-3" dangerouslySetInnerHTML={{__html: t('whoIsFooter')}}>
      </div>
    </div>
  )
}