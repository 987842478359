import React from "react";
import { useTranslation } from "react-i18next";
import fbIcon from "./../../images/facebook.png";
import instaIcon from "./../../images/instagram.png";

export const Abni = () => {
  const { t } = useTranslation();

  return(
    <div className="max-w-6xl m-auto content-block px-5 mt-6" id="link-anbi"> 
      <div className="font-bold text-2xl pb-2 text-main-color"> 
        Algemene informatie
      </div>
      <div className="flex flex-col lg:flex-row  gap-x-14">
        <div className="flex flex-col"> 
          <div className="font-semibold">
            Stichting Twentse Kinderhulp Oekraïne
          </div>
          <div>
            Molenstraat 10 <br/>
            7671 KB Vriezenveen<br/>
            Overijssel, Nederland<br/>
          </div>
          
          <a target="_blank" className="hover:text-stko-orange hover:underline cursor-pointer" href="https://www.geef.nl/nl/doel/kinderhulp-tsjernobylramp-wit-rusland-stichting/anbi">
            <img 
              className="w-24 mt-4"
              src={"https://www.geef.nl/assets/anbi_80.png"}
            />
          </a>
        </div>  
        <div className="">
          <table>
            <tr>
              <td >{t("email")}</td>
              <td className="pl-4 font-semibold"><a href="mailto:info@stko.nl" className="hover:underline">info@stko.nl</a></td>
            </tr>
            {/* <tr>
            <td >{t("phone")}</td>
              <td className="pl-4 font-semibold"><a href="tel:+310546563602" className="hover:underline">+31 (0) 546 563602</a></td>
            </tr> */}
            <tr>
            <td >{t("mobile")}</td>
              <td className="pl-4 font-semibold"><a href="tel:0616728321" className="hover:underline">+31 6 16 72 83 21</a></td>
            </tr>
            <tr>
            <td >{t("kvk")}</td>
              <td className="pl-4 font-semibold">41031769</td>
            </tr>
            <tr>
              <td>BIC:</td>
              <td className="pl-4 font-semibold">RABONL2U</td>
            </tr> 
            <tr>
              <td>IBAN:</td>
              <td className="pl-4 font-semibold">NL30RABO0125713843</td>
            </tr> 
            <tr>
              <td>Socials:</td>
              <td className="pl-4 font-semibold">
                <div className="flex flex-row gap-x-2">
                  <a href="https://www.facebook.com/StichtingTwentseKinderhulpOekraine" target="_blank" className="hover:underline">
                    <div>
                      <img src={fbIcon} className="w-6 inline"/>
                    </div>
                  </a>
                  <a href="https://www.instagram.com/twentsekinderhulpoekraine?igsh=MTF5MmYzb3AyaDBrdQ==" target="_blank" className="hover:underline">
                    <div>
                      <img src={instaIcon} className="w-6 inline"/>
                    </div>
                  </a>
                </div>
              </td>
            </tr> 
          </table>
        </div> 
      </div>
    </div>
  )
}