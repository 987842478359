import { useTranslation } from "react-i18next";

import TeamImg from "../../images/TeamImg.png";
import { Participant } from "./Participant"; 
import GerardImg from "../../images/GerardImg.png";
import MargienImg from "../../images/MargienImg.png";
import CarolineImg from "../../images/CarolineImg.png";

export const MainTeam = () => {
  const { t } = useTranslation();
  
  const team = [
    {
      name: "Gerard Hulsegge",
      position: "Voorzitter",
      img: GerardImg
    },
    {
      name: "Margien Wessels",
      position: "Secretaris",
      img: MargienImg
    },
    {
      name: "Caroline de Jong",
      position: "Penningmeester",
      img: CarolineImg
    },
  ]
 



  return(
    <div className="content-block text-white bg-main-color" id="link-team">
      <div className="max-w-6xl m-auto w-full px-5 text-center lg:text-left">
        <div className="flex flex-col lg:flex-row lg:gap-x-8">
          <div className="flex flex-col place-content-center">
            <div className="header-text-white">
              {t("whoIsHeader")}
            </div>
            <div className="mt-4" dangerouslySetInnerHTML={{__html: t('whoIsText')}}>
            </div>
          </div>
          <img 
            className="mt-8 lg:mt-0 w-3/5 hidden md:block"
            src={TeamImg}
          />
        </div>
        <div className="header-text-white pt-8">
          {t("whoIsBestuur")}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          {team.map((participant, index) => (
            <Participant data={participant} key={index} isNew={false}/>
          ))}
          {/* {isAdmin && 
            <div>
              <Participant data={emptyParticipant} key={999} isNew={true} refresh={getMainTeam}/>
            </div>
          } */}
        </div>
      </div>
    </div>
  )
}