import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Project } from "./Project";
import { ButtonSmall } from "../buttons/ButtonSmall";
import { fetchProjects } from "../../API/api";
import { ProjectType } from "../../lists/types";

import fakeProjects from "../../lists/Projects.json";

export const PreviousProjects = () => {
  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  
  useEffect(() => {
    const getProjects = async () => {
      try {
        const data = await fetchProjects();
        setProjects(data);
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      } finally {
        setLoading(false);
      }
    };

    getProjects();
  }, []);

  const { t } = useTranslation();
  return(
    <div className="content-block" id="link-projects">
      <div className="max-w-6xl m-auto w-full text-center lg:text-left px-4"> 
        <div className="flex flex-col place-content-center">
          <div className="header-text">
            {t("previousProjectsHeader")}
          </div>
        </div> 
        <div className="py-4 ">
          {projects.map((project, index) => (
            index <= 2 &&
              <Project data={project} key={index}/>
          ))}
        </div>
        <div className="m-auto lg:ml-auto w-fit mt-4">
          <ButtonSmall text={t("viewAllText")} link="/overview" />
        </div>
      </div>
    </div>
  )
}