import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectBanner } from "../components/main-banner/ProjectBanner";

// import { TextEditor } from "../components/wysiwyg/TextEditor";
import { PencilIcon } from "@heroicons/react/24/solid";
import { ProjectType } from "../lists/types";
import { fetchProjects, updateProject } from "../API/api";
import { useNavigate } from "react-router-dom";
import { TextEditor } from "../components/wysiwyg/TextEditor";

export const ProjectPage = () => {
  const [item, setItem] = React.useState({} as any)
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false)
  const toggleEdit = () => {setEdit(!edit)}
  const [removeProject, setRemoveProject] = useState(false)
  const [content, setContent] = useState(''); 

  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = React.useState(false)

  const navigate  = useNavigate()
  
  useEffect(() => {
    if(localStorage.getItem('stko') === 'true'){
      setIsAdmin(true)
    }
  }, [])

  useEffect(() => {
    const getProjects = async () => {
      try {
        const data = await fetchProjects();
        setProjects(data);
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      } finally {
        setLoading(false);
      }
    };

    getProjects();
  }, []);

  useEffect(() => {
    if(projects.length > 0){
      let i = projects.filter((item) => String(item._id) === window.location.pathname.split('/')[2])
      // console.log('i', i[0])
      setItem(i[0])
      if (i[0].wysiwyg) {
        setContent(i[0].wysiwyg);
      }
    }
  },[projects])

  const updateCurrentProject = () => {
    let fields = item
    fields.wysiwyg = content
    try{
      updateProject(item._id, fields)
      navigate('/overview')
      toggleEdit()

    } catch(error) {
      console.error('Failed to update team member:', error);
    } 
  }

  const handleRemoveProject = () => {
    let fields = item
    fields.archived = true
    try{
      updateProject(item._id, fields)
      toggleEdit()
      setRemoveProject(false)
      // resetFields()
    } catch(error) {
      console.error('Failed to remove team member:', error);
    }
  }

  return (
    <div className="relative font-gordita">
      <ProjectBanner item={item} setItem={setItem} edit={edit}/> 
      {isAdmin &&
        <div className={`text-sm flex absolute top-10 z-10 right-10 ${edit ? "text-green-300 hover:text-green-500 animate" : "text-gray-400 animate hover:text-gray-100"} cursor-pointer`} onClick={() => edit ? updateCurrentProject() : toggleEdit()}>
          {edit ? <span className="">Opslaan</span> : "Aanpassen"} <PencilIcon className="ml-2 w-6 h-6"/>
        </div>
      }
      {item &&
        <div className="container mx-auto max-w-6xl px-4 relative">
          {edit &&
            <div className="my-6">
              <TextEditor content={content} setContent={setContent} toggleEdit={toggleEdit}/>
            </div>
          }
          <div className="text-black text-xl lg:text-2xl text-left py-4 lg:hidden"> 
            {item.body}
          </div> 

          <div className="my-6" dangerouslySetInnerHTML={{__html: content}}>
          </div>
          {edit &&
            <div className="flex">
              <div className="w-fit mt-4">
                <button className="bg-main-color text-white p-2 rounded-lg" onClick={()=>updateCurrentProject()}>{t("Opslaan")}</button>
              </div>
              <div className="m-auto lg:ml-auto w-fit mt-4">
                <button className="bg-red-500 hover:bg-red-400 active:bg-red-300 text-white p-2 rounded-lg" onClick={()=>removeProject ? handleRemoveProject() : setRemoveProject(true)}>{removeProject ? "Bevestigen" : "Project Verwijderen"}</button>
              </div>
            </div>
          }
        </div>
      }
    </div>
  );
}   